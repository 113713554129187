<template>
  <div class="UserHome">
    <div class="page-header">
      我的主页
    </div>
    <div class="UserInfo">
      <!-- 头像 -->
      <el-avatar :size="108" :src="UserInfo.avatarImg"></el-avatar>
      <!-- 昵称 -->
      <div class="Nick">
        <div class="NickName">{{UserInfo.nickName}}</div>
        <div class="vip">vip{{ServeInfo.providerGrade}}</div>
      </div>
    </div>
    <div class="OrderInfo">
      <div class="score">
        <div class="score-txt">
          5.0
          <span>分</span>
        </div>
        <div class="rate">
          <el-rate void-icon-class="el-icon-star-on" disabled allow-half v-model="rate"></el-rate>
        </div>
      </div>
      <div class="order">
        <div class="order-score">
          <div class="img">
            <img src="@/assets/imgs/user/dengji.png" alt />
          </div>
          <div class="data">
            <div>买家等级</div>
            <div>VIP{{ServeInfo.providerGrade}}</div>
          </div>
        </div>
        <div class="order-score">
          <div class="img">
            <img src="@/assets/imgs/user/haopin.png" alt />
          </div>
          <div class="data">
            <div>总好评数</div>
            <div>0</div>
          </div>
        </div>
      </div>
      <div class="order">
        <div class="order-score">
          <div class="img">
            <img src="@/assets/imgs/user/order.png" alt />
          </div>
          <div class="data">
            <div>购买订单数</div>
            <div>0</div>
          </div>
        </div>
        <div class="order-score">
          <div class="img">
            <img src="@/assets/imgs/user/haoping.png" alt />
          </div>
          <div class="data">
            <div>好评率</div>
            <div>--</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      UserInfo: "",
      ServeInfo: "",
      rate: 5,
    };
  },
  created() {
    this.ServiceHome();
    this.UserInfo = this.$store.getters.getUser;
  },
  methods: {
    ServiceHome() {
      let url =
        "/app-api/hiring/user/get?id=";
      let ids = this.$store.getters.getUser.id;
      this.$axios
        .get(url + ids)
        .then((res) => {
          if (res.data.code === 0) {
            this.ServeInfo = res.data.data;
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.page-header {
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-bottom: 30px;
}
.UserHome {
  //   padding: 20px;
  //   box-sizing: border-box;
  width: 100%;
  //   border-radius: 8px;
  .UserInfo {
    border: 1px solid #e8e8e8;
    background: #ffffff;
    width: 100%;
    border-radius: 8px;
    padding: 40px 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .Nick {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .NickName {
        font-weight: bold;
        color: #000000;
        line-height: 23px;
        font-size: 20px;
      }
      .vip {
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        font-size: 12px;
        height: 16px;
        padding: 0 6px;
        margin-left: 10px;
        border: 1px solid #999999;
        border-radius: 3px;
      }
    }
  }
  .OrderInfo {
    border: 1px solid #e8e8e8;
    width: 100%;
    border-radius: 8px;
    margin-top: 30px;
    padding: 40px 80px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background: #ffffff;
    .score {
      width: 271px;
      height: 153px;
      background: #fafafb;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .score-txt {
        font-weight: bold;
        color: #000000;
        line-height: 47px;
        letter-spacing: 2px;
        font-size: 40px;
        span {
          font-weight: 400;
          color: #999999;
          line-height: 19px;
          font-size: 16px;
          //   margin-left: 10px;
        }
      }
      .rate {
        margin-top: 18px;
        /deep/ .el-rate__icon {
          font-size: 24px;
        }
      }
    }
    .order {
      .order-score {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
        .img {
          width: 56px;
          height: 56px;
          background: #f4f4f4;
          margin-right: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 15px;
            height: auto;
          }
        }
        .data {
          div:nth-of-type(1) {
            font-weight: 400;
            color: #999999;
            line-height: 19px;
            font-size: 16px;
          }
          div:nth-of-type(2) {
            font-weight: bold;
            color: #000000;
            line-height: 28px;
            font-size: 24px;
            margin-top: 4px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
